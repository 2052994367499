import React, { createContext, FC, PropsWithChildren, useState } from 'react';

interface JavascriptLoaderState {
  scriptsAreLoaded: (scripts: string[]) => boolean;
  loadScripts: (scripts: string[]) => void;
}

export const JavascriptLoader = createContext<JavascriptLoaderState>({
  scriptsAreLoaded: () => false,
  loadScripts: () => {},
});

export const JavascriptLoaderProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [loadedScripts, setLoadedScript] = useState<string[]>([]);
  const scriptsAreLoaded = (scripts: string[]) =>
    !scripts.some((script) => !loadedScripts.includes(script));

  const loadScripts = (scripts: string[]) => {
    scripts.forEach((script) => {
      if (!loadedScripts.includes(script)) {
        const scriptTag = document.createElement('script');
        scriptTag.src = script;
        scriptTag.type = 'text/javascript';
        scriptTag.addEventListener('load', () =>
          setLoadedScript((prev) => {
            const loaded = prev.concat(script);
            return loaded;
          }),
        );
        document.body.appendChild(scriptTag);
      }
    });
  };

  return (
    <JavascriptLoader.Provider
      value={{
        scriptsAreLoaded,
        loadScripts,
      }}
    >
      {children}
    </JavascriptLoader.Provider>
  );
};
